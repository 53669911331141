#page-header-dropdown .menu > .header {
  text-transform: unset;
}

.ui.dropdown .menu {
  z-index: 30 !important;
}

.ui.menu.page-header {
  background: @white !important;
  border-bottom: 1px solid #ddd !important;
  padding: 0.5rem;
  z-index: 1000;
}

.ui.menu.page-header .item {
  font-weight: bold;
  align-self: normal;
}

.ui.menu.page-header .button {
  line-height: inherit;
  margin: 0.3em 0.5em 0.3em 0;
}

.ui.menu.page-header .item:hover,
.ui.menu.page-header .active.item,
.ui.menu.page-header .active.item:hover,
.ui.menu.page-footer .item:hover,
.ui.menu.page-footer .active.item,
.ui.menu.page-footer .active.item:hover {
  color: #0366d6;
  border-color: @white;
}

.ui.menu.page-header .pointing.dropdown > .menu {
  margin: .5em 0 0 !important;
}

.ui.dropdown .menu > a.item:hover {
  text-decoration: none;
}

.ui.menu.page-footer {
  z-index: 1000;
}

.ui.menu.project-tabs {
  margin-top: -1em;
}

.ui.menu.project-tabs .menu.right.more {
  display: none;
}

.ui.menu.project-tabs .item {
  padding: .675em 1em;
}

.ui.dropdown .menu .item.delete,
.ui.dropdown .menu .item.delete:hover {
  color: @red !important;
}

td .ui.menu .ui.dropdown .menu > .item {
  min-height: unset;
}

.ui.menu .ui.dropdown .menu > .item.delete,
.ui.menu .ui.dropdown .menu > .item.delete:hover {
  color: @red !important;
}

.ui.menu.page-footer #page-message i.icon {
  display: none;
}

.ui.menu.page-footer #page-message .ui.text {
  display: block;
}

@media only screen and (max-width: @largestMobileScreen) {
  .ui.menu.page-header .header.item:not(.authenticated) {
    display: none;
  }

  .ui.menu.page-header .item.pricing,
  .ui.menu.page-header .item.templates {
    display: none;
  }

  .ui.menu.page-footer #editor-avatars {
    display: none;
  }

  .ui.menu.page-footer #page-message i.icon {
    display: block;
  }

  .ui.menu.page-footer #page-message .ui.text {
    display: none;
  }

  .ui.menu.page-header {
    padding: 0;
  }

  .ui.menu.page-header .menu.right .item {
    margin-left: 0;
  }

  .ui.menu.page-header .menu.right .item:not(:last-child) {
    margin-right: 0;
  }

  .ui.menu.project-tabs .item:not(:nth-child(-n + 3)) {
    display: none;
  }

  .ui.menu.project-tabs .menu.right.more {
    display: block;
  }

  .ui.menu.project-tabs .item:last-child {
    padding-right: 0;
  }

  .geSidebarContainer .ui.top.attached.tabular.menu > .item:first-child {
      margin-left: unset !important;
  }

  .grapheditor-container {
    height: 200px;
  }
}