#home-body-header {
  padding: 2em;
  background-color: rgb(244, 250, 255);
  border: medium none;
}

#home-body-header h1 {
  font-size: 2.5em;
}

#home-body-footer {
  margin: 1em 0em;
  padding: 2em;
  background-color: rgb(245, 243, 244);
  border: medium none;
}

#home-body-footer h1 {
  font-size: 1.7em;
  font-weight: normal;
}

.page-content {
  margin-top: 6em;
  margin-left: 1em;
  margin-right: 1em;
}

.page-content.simple-public {
  margin-top: 0 !important;
}

.page-content.projects,
.page-content.settings,
.page-content.account,
.page-content.pages,
.page-content.projects {
  margin-top: 4.5em;
}

@media only screen and (max-width: @largestMobileScreen) {
  .page-content,
  .page-content.projects,
  .page-content.settings,
  .page-content.account,
  .page-content.pages {
    margin-top: 4em;
  }

  #home-body-footer,
  #home-body-header {
    padding: 1em;
  }

  #home-body-header h1 {
    font-size: 2em;
  }
}

.page-content.pages #page-meta {
  margin: 0;
  color: rgba(0,0,0,.4);
  font-size: @tiny;
  font-style: normal;
}

.page-content.pages .table-of-contents h2 {
  margin-bottom: .5em;
}

.page-content-simple {
  padding: 4em 2em 0 2em;
}

.markdown-body {
  word-wrap: break-word;
}

.markdown-body a.image-zoom {
  margin: 5px !important;
}

.markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
  text-align: initial;
}

.markdown-body code, .geSidebarContainer code {
  color: inherit;
  padding: .2em .4em;
  margin: 0;
  font-size: @tiny;
  word-break: normal;
  background-color: rgba(0,0,0,5%);
  border-radius: .25rem;
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace !important;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
}

.markdown-body pre {
  word-wrap: normal;
  padding: 0;
  overflow: auto;
  line-height: 1.45;
  margin: 0;
}

.markdown-body pre code, .geSidebarContainer pre code {
  border: 0;
  background-color: initial;
}

.markdown-body pre.ui.error {
  color: #db2828;
}

.markdown-body pre.ui.error code {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

div.math, div.mermaid, div.plantuml {
  margin: 0 0 1em;
  display: flex !important;
  flex-direction: column;
  padding: 1em;
  align-items: center;
}

span.math {
  display: inline-block;
}

#editor-avatars {
  padding-left: 1.125em
}

#editor-avatars span {
  margin-right: 5px;
}

#editor-avatars span img {
  padding: 1px;
}

img.emoji {
  height: 1em !important;
  width: 1em !important;
  vertical-align: -0.1em !important;
}

.ui.dropdown .menu > .item > img.emoji {
  margin-top: unset;
  margin-bottom: unset;
}

div.resize-handle {
  position: absolute;
  border: 1px solid @greyBorderColor;
  background: @greyBackground;
  width: 10px;
  height: 10px;
  z-index: 100;
  margin: 0;
  padding: 0;
}

.lity-content {
  background-color: #ffffff;
}

.code-block pre {
  margin-top: 0 !important;
}

details summary {
  padding: .5em 0;
  cursor: pointer;
}

details summary > * {
  display: inline;
}

.CodeMirror.auto-height {
  height: auto;
}

.CodeMirror.auto-height .CodeMirror-scroll {
  max-height: 300px;
}

#workflowDefinitionVersionsDropdown .menu > .item {
  text-decoration: none;
}

.page-content .anchor {
  color: @textColor;
  text-decoration: none;
  cursor: pointer;
}

.page-content .anchor::after {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z'/%3E%3C/svg%3E");
  opacity: 0;
  margin-left: .2em;
  height: .8em;
  width: .8em;
  display: inline-block;
}

.page-content .anchor:hover::after {
  opacity: 1;
}

code.api-code {
  white-space: -moz-pre-wrap; /* Mozilla */
  white-space: -hp-pre-wrap; /* HP printers */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
  word-break: break-all;
}

.page-footer .ui.horizontal.divided.list {
    display: block;
}

.powered-by a .ui.image {
    width: 125px
}