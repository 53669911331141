.ui.dropdown.active.visible div.menu div.item svg.ui.image {
  margin-top: -0.15em
}

img.icon-12 {
  width: 12px !important;
  height: 12px !important;
}

img.icon-14 {
  width: 14px !important;
  height: 14px !important;
}

img.icon-16 {
  width: 16px !important;
  height: 16px !important;
}

img.icon-18 {
  width: 18px !important;
  height: 18px !important;
}

img.icon-22 {
  width: 22px !important;
  height: 22px !important;
}

img.icon-24 {
  width: 24px !important;
  height: 24px !important;
}

img.icon-white {
  filter: invert(100%);
}

img.icon-light {
  filter: invert(40%);
}

img.logo {
  width: 160px !important;
  height: 30px !important;
}

img.logo-large {
  width: 220px !important;
  height: 41px !important;
}

a.sortable img.icon {
  margin-left: 5px;
}