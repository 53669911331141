.ui.card:not(.template) > .content > .header,
.ui.card:not(.template) > .content > .header:not(.ui) {
  font-size: unset;
}

.ui.card > .content > .meta {
  margin-top: 0.5em;
}

.ui.card > .extra.content {
  font-size: @tiny;
}

.ui.grid .column > .column-resize-handle + .ui.card,
.ui.grid .column > .grip-row-left + .grip-row-right + .ui.card {
  margin-top: 0;
}

.ui.grid .column a.ui.card:hover {
  text-decoration: none;
}