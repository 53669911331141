.page-content .ui.right.floated.button.primary {
  margin-top: 0.2em;
}

.page-content .ui.right.floated.buttons {
  margin-left: 10px;
}

.page-content .ui.right.floated.buttons .menu .item {
  text-decoration: none;
}

.ui.basic.button.authentication .icon {
    opacity: 1;
    background: 1px #fff !important;
    box-shadow: 0 0 0 1px #22242626 inset !important;
    padding: 10px !important;
}

.ui.basic.button.authentication:hover .icon {
    box-shadow: inset 0 0 0 1px #22242659,inset 0 0 0 0 #22242626 !important;
}