.ui.accordion .title {
    cursor: default;
}

.ui.accordion .title .expand {
    cursor: pointer;
}

.text-editor .ui.accordion .title > *,
.markdown-body .ui.accordion .title > * {
  display: inline-flex;
}

table.ui.accordion tr.title td .dropdown.icon.expand {
    font-family: Accordion;
    line-height: 1;
    backface-visibility: hidden;
    font-weight: normal;
    font-style: normal;
    text-align: center;
}
table.ui.accordion tr.title td .dropdown.icon.expand::before {
    content: '\f0da'/*rtl:'\f0d9'*/;
}

table.ui.accordion tr.title.active td .dropdown.icon.expand {
    transform: rotate(90deg);
}

.ui.accordion .accordion:not(details) .title ~ .content:not(.active),
.ui.accordion:not(details) .title ~ .content:not(.active) {
    display: none !important;
}