.grapheditor-properties.segment {
  box-shadow: none;
  padding: 0;
}

.grapheditor-container.segment {
  box-shadow: none;
}

div.segment.code-block {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  padding: 0;
  text-align: initial;
}

div.segment.code-block .inline.loader.active {
  margin-bottom: 1.85em;
}

div.segment.code-block.CodeMirror {
  margin-top: 1.85em;
  height: auto !important;
  padding: 0;
  margin-bottom: 4em;
}

div.segment.code-block .CodeMirror {
  margin-top: 1.85em;
  height: auto !important;
}

div.segment.code-block .CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}

.ui.raised.segments .CodeMirror.ui.segment {
  margin: 0;
  padding: 0;
}

.ui.segment.code-block .ui.top.attached.label .detail,
.ui.segment.message-block .ui.top.attached.label .detail {
  font-weight: normal;
  opacity: 1;
}

.ui.segment.code-block .ui.top.attached.label .detail div.text.default,
.ui.segment.message-block .ui.top.attached.label .detail div.text.default {
  color: inherit;
}

.ui.raised.segments .CodeMirror.ui.segment {
  height: auto !important;
}

.ui.raised.segments .CodeMirror-scroll.ui.segment {
  overflow-y: hidden;
  overflow-x: auto;
}

.ui.raised.segments {
  margin-bottom: 1em;
}

.ui.raised.segments .inline.loader.active {
  margin-top: 1em;
  margin-bottom: 1em;
}

.ui.segment.code-block .ui.top.right.attached.label a {
  text-decoration: none;
}