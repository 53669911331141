.ui.pointing.label:before,
.ui[class*="pointing above"].label:before {
  left: 1em;
}

.ui.checkbox label {
  cursor: default !important;
}

.ui.basic.label.authentication img {
    padding: 8px;
}


@media only screen and (max-width: @largestMobileScreen) {
  #graph-enabled-label {
    display: none;
  }
}

a.ui.label {
  text-decoration: none;
}

.ui.label a .icon {
  text-decoration: none;
  padding: 0;
  margin: 0;
}