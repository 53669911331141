each(@colors, {
  @color: replace(@key, '@', '');
  @l: @colors[@@color][light];
  @t: @colors[@@color][text];
  @b: @colors[@@color][bright];
  @isDark: @colors[@@color][isDark];
  @isVeryDark: @colors[@@color][isVeryDark];

  .ui.grid > .@{color}.row,
  .ui.grid > .@{color}.column,
  .ui.grid > .row > .@{color}.column {
    & when (@isDark) {
      background: @l;
    }
    & when not (@isDark) {
      background: @b;
    }
    & when (@isVeryDark) {
      color: @white;
    }
    & when not (@isVeryDark) {
      color: @t;
    }
  }
})

.page-content .ui.grid {
  margin-top: unset !important;
  margin-bottom: unset !important;
}

.page-content .ui.grid .column .ui.card.draggable {
  cursor: move;
}

.page-content .ui.grid .column .ui.card.draggable:hover {
  background: @selectableBackground;
  color: @selectableTextColor;
}

.page-content .ui.grid .column .ui.card.draggable.chosen {
  background: @positiveBackgroundHover;
  color: @positiveColorHover;
}

.page-content .ui.grid .column .ui.card.draggable.ghost {
  box-shadow: @activeBoxShadow;
  background: @activeBackgroundColor;
  color: @activeColor;
  opacity: 0.4;
}