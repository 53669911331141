.markdown-body li p:first-child {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body p:first-child {
  margin-top: 0
}

.markdown-body p:last-child {
  margin-bottom: 0
}

ol, ul {
  padding: 0 0 0 2.5rem;
  text-align: initial;
}

ol li:first-child, ul li:first-child {
  padding-top: 0;
}

ul li {
  position: relative;
}

ol li, ul li {
  display: list-item;
  table-layout: fixed;
  padding-bottom: .1875em;
  line-height: 1.125em;
}

ol ol, ul ul, ol ul, ul ol {
  clear: both;
  padding-top: .75em;
  padding-bottom: .25em;
}

dl dt {
  padding: 1em 0 1em 0;
  font-weight: bold;
}

ul.task-list {
  list-style: none;
  padding: 0 0 0 1rem;
}

ul.task-list li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

ul.task-list li input {
  pointer-events: initial;
  opacity: 1;
  margin: 0.15em 0.5em 0 0;
}

ul.task-list li.checked > div {
  color: #4E5C6E;
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}

ul.task-list li span {
  padding: 0;
  margin: 0;
}

ul.task-list {
  list-style-type: none;
  margin-left: 0;
}

ul.task-list ul.task-list {
  padding-top: .75em;
  padding-bottom: .25em;
}

ul.task-list li,
ul.task-list ul.task-list li {
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
}