.page-content form {
  margin-top: 1em;
}

td input[type="checkbox"], td input[type="radio"] {
  vertical-align: unset !important;
}

.ui.form .grouped.fields {
    margin: 0;
}

.ui.category.search > .results .category > .name {
  padding: 0.5em 1em !important;
  line-height: 1.2em;
}

#variable-form .field .CodeMirror {
  margin: 0;
}