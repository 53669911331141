/* Headers */
.ui.table > tbody > tr > th {
  cursor: auto;
  background: @headerBackground;
  text-align: @headerAlign;
  color: @headerColor;
  padding: @headerVerticalPadding @headerHorizontalPadding;
  vertical-align: @headerVerticalAlign;
  font-style: @headerFontStyle;
  font-weight: @headerFontWeight;
  text-transform: @headerTextTransform;
  border-bottom: @headerBorder;
  border-left: @headerDivider;
}

.ui.table > tr > th {
  cursor: auto;
  background: @headerBackground;
  text-align: @headerAlign;
  color: @headerColor;
  padding: @headerVerticalPadding @headerHorizontalPadding;
  vertical-align: @headerVerticalAlign;
  font-style: @headerFontStyle;
  font-weight: @headerFontWeight;
  text-transform: @headerTextTransform;
  border-bottom: @headerBorder;
  border-left: @headerDivider;
}

.ui.table > tbody > tr > th:first-child {
  border-left: none;
}

.ui.table > tr > th:first-child {
  border-left: none;
}

.ui.table > tbody > tr:first-child > th:first-child {
  border-radius: @borderRadius 0 0 0;
}

.ui.table > tr:first-child > th:first-child {
  border-radius: @borderRadius 0 0 0;
}

.ui.table > tbody > tr:first-child > th:last-child {
  border-radius: 0 @borderRadius 0 0;
}

.ui.table > tr:first-child > th:last-child {
  border-radius: 0 @borderRadius 0 0;
}

.ui.table > tbody > tr:first-child > th:only-child {
  border-radius: @borderRadius @borderRadius 0 0;
}

.ui.table > tr:first-child > th:only-child {
  border-radius: @borderRadius @borderRadius 0 0;
}

.ui.table > tbody > tr.draggable {
  cursor: move;
}

.ui.table > tbody > tr.draggable:hover {
  background: @selectableBackground;
  color: @selectableTextColor;
}

.ui.table > tbody > tr.draggable.chosen {
  background: @positiveBackgroundHover;
  color: @positiveColorHover;
}

.ui.table > tbody > tr.draggable.ghost {
  box-shadow: @activeBoxShadow;
  background: @activeBackgroundColor;
  color: @activeColor;
  opacity: 0.4;
}

each(@colors, {
  @color: replace(@key, '@', '');
  @c: @colors[@@color][color];

  .ui.table tr th.@{color} {
      background-color: @c !important;
      color: @white !important;
  }
})

.page-content .ui.table {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.page-content .ui.table td, .page-content .ui.table th {
  position: relative;
  padding: 0.7125em;
}

.ui.table .collapsing svg {
    max-width: unset;
    height: unset;
}

.ui.accordion .collapsing.fitted {
    padding: 0;
}

.ui.table tr.hidden {
    display: none;
}

.ui.grid .ui.card.hidden {
    display: none;
}

.ui.table.variables .CodeMirror {
  height: auto;
  margin-top: 0px;
}