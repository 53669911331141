#page-header-search {
  padding-top: 0;
  padding-bottom: 0;
  width: 500px;
}

.ui.menu.page-header .search input {
  padding-right: 2.6em !important;
  border-radius: 500rem;
}

.ui.menu.page-header .search button {
  position: absolute;
  padding: 5px !important;
  right: -5px;
  top: -1.5px;
  opacity: .5;
}

@media only screen and (max-width: @largestMobileScreen) {
  .ui.menu.page-header .tiny.search {
    display: none;
  }

  #page-header-search {
    max-width: e("calc(100vw - 2rem - 36px)");
    padding: 0 2px;
  }
}

span.keyword {
  font-weight: bold;
}

.ui.search > .results a.result,
.ui.category.search > .results a.result {
  text-decoration: none;
}

.ui.category.search > .results .category {
  display: block;
}

.ui.category.search > .results .category > .name {
  display: block;
  padding: 1em;
  width: unset;
}

.ui.category.search > .results .category .results {
  display: block;
}

#openApisSearch > .results .result .image {
  width: auto;
}

#openApisSearch .ui.icon.input > i.icon img {
  padding: 10px;
}

#page-search-tooltip .results {
  width: 28em;
}