.ui.page-content.pages h1:first-of-type,
.ui.page-content.account h1:first-of-type,
.ui.page-content.projects h1:first-of-type,
.ui.page-content.settings h1:first-of-type {
  margin-top: 0;
}

.text-editor h1:first-of-type,
.text-editor h2:first-of-type,
.text-editor h3:first-of-type,
.text-editor h4:first-of-type,
.text-editor h5:first-of-type {
  margin-top: 0;
}

.ui.page-content.pages.markdown-body:not(.task,.public) h1:first-of-type {
  margin: 0
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  scroll-margin-top: 75px;
}

@media only screen and (max-width: @largestMobileScreen) {
  .ui.header.id {
    overflow-wrap: break-word;
  }
}