.markdown-body hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}

.markdown-body hr:after {
  clear: both;
}

table#validationRules .field .ui.divider {
  margin: .5rem 0;
}